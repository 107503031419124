import { ThemeQuery } from '@generated/graphql';

export const getCurrentLanguage = (themeData: Pick<NonNullable<ThemeQuery['theme']>, 'defaultLanguage'>) => (
  new URLSearchParams(window.location.search).get('language') || themeData.defaultLanguage
);

const getTranslation = (themeData: NonNullable<ThemeQuery['theme']>) => {
  const language = getCurrentLanguage(themeData);
  return (language && themeData.translations.find((t) => t.language === language)) || themeData.translations[0];
};

export default getTranslation;
