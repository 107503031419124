import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import App from './App';
import init18n from './i18n';

Sentry.init({
  dsn: 'https://571991086175176230b7eb4d78e478a3@o1054521.ingest.sentry.io/4506299411333120',

  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],

  ignoreErrors: ['jQuery'],

  tracesSampleRate: 0.5,
  tracePropagationTargets: ['localhost', /^https:\/\/(.+?)\/graphql/],
  profilesSampleRate: 0.5,

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root')!);

init18n.then(() => {
  root.render(<App />);
});
