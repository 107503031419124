import { ThemeQuery } from '@generated/graphql';
import type {} from '@mui/lab/themeAugmentation';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import getTranslation from '../Common/translation';
import useClientTheme from '../Hooks/useClientTheme';
import LanguageProvider from './LanguageProvider';
import Loading from './Loading';
import UnderConstruction from './UnderConstruction';

const defaultMuiTheme = createTheme();

const createDynamicTheme = (theme: NonNullable<ThemeQuery['theme']>) => {
  const pxToRem = (value: number) => `${value / theme.fontSize}rem`;
  const translation = getTranslation(theme);

  return createTheme({
    direction: translation.rightToLeft ? 'rtl' : 'ltr',
    palette: {
      contrastThreshold: 2.6,
      primary: {
        main: theme.primaryColor,
      },
      secondary: {
        main: theme.secondaryColor,
      },
      background: {
        default: theme.bodyBackground,
      },
      text: {
        primary: theme.textColor,
      },
    },
    typography: {
      fontFamily: theme.fontFamily,
      fontSize: theme.fontSize,
      h2: {
        fontFamily: theme.headingFontFamily,
        fontSize: pxToRem(32),
        fontWeight: 900,
        textTransform: theme.textTransform as any,
        [defaultMuiTheme.breakpoints.down('sm')]: {
          fontSize: pxToRem(20),
        },
      },
      h4: {
        color: theme.primaryColor,
        fontSize: pxToRem(24),
        fontWeight: 900,
        [defaultMuiTheme.breakpoints.down('sm')]: {
          fontSize: pxToRem(16),
        },
      },
      h5: {
        fontWeight: 'bold',
      },
      h6: {
        fontSize: pxToRem(18),
        fontWeight: 900,
        [defaultMuiTheme.breakpoints.down('sm')]: {
          fontSize: pxToRem(16),
        },
      },
      button: {
        fontFamily: theme.fontFamily,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1240,
        xl: 1536,
      },
    },
    components: {
      MuiLoadingButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: theme.buttonFontFamily,
            fontWeight: 900,
            borderRadius: `${theme.buttonRadius ?? 0}px`,
            padding: `${theme.buttonVerticalPadding}px ${theme.buttonHorizontalPadding}px`,
            textTransform: theme.textTransform as any,
            [defaultMuiTheme.breakpoints.down('md')]: {
              padding: `${theme.buttonVerticalPadding * 0.8}px ${theme.buttonHorizontalPadding * 0.8}px`,
              wordBreak: 'break-all',
            },
            '&:hover': {
              color: theme.buttonPrimaryHoverText,
            },
          },
        },
        defaultProps: {
          disableRipple: true,
          variant: 'contained',
          disableElevation: !theme.buttonElevation,
        },
      },
    },
  });
};

type ThemeProps = {
  children: ReactNode;
};

const Theme: FunctionComponent<ThemeProps> = ({ children }) => {
  const { themeData, themeLoading, networkError } = useClientTheme();

  if (themeLoading) return <Loading />;

  if (networkError) return <UnderConstruction />;
  if (!themeData) return <p>Something went wrong: No theme could be found</p>;
  if (!themeData.translations.length) return <p>Something went wrong: No translations could be found for this theme</p>;

  const theme = createDynamicTheme(themeData);
  const { language } = getTranslation(themeData);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LanguageProvider language={language} direction={theme.direction}>
        {children}
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default Theme;
