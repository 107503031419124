import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './Assets/translations/en.json';
import nl from './Assets/translations/nl.json';

const init18n = i18n.use(initReactI18next).init({
  supportedLngs: ['en', 'nl'],
  fallbackLng: (code) => (code === 'vl' ? ['nl', 'en'] : ['en', 'nl']),
  resources: {
    en: {
      translation: en,
    },
    nl: {
      translation: nl,
    },
  },
  interpolation: {
    escapeValue: false, // Not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
});

export default init18n;
