import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: ReactNode;
  direction: string;
  language: string;
};

const LanguageProvider = ({ children, direction, language }: Props) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  document.documentElement.lang = language;
  document.dir = direction;

  return children;
};

export default LanguageProvider;
