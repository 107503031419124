import React, {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box, capitalize, CardMedia, Grid, TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCreateParticipationMutation } from '@generated/graphql';
import { useTranslation } from 'react-i18next';
import { LanguageSelector, Loading } from '../Components';
import useClientTheme from '../Hooks/useClientTheme';
import getTranslation from '../Common/translation';
import { toBoolean } from '../Common/string';

const Start: FunctionComponent = () => {
  const { t } = useTranslation();
  const { themeData, themeLoading } = useClientTheme();

  const [respondentName, setRespondentName] = useState<string | undefined>();
  const [respondentEmail, setRespondentEmail] = useState<string | undefined>();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [createParticipationMutation, { loading: loadingCreateParticipation, error }] = useCreateParticipationMutation();

  const handleStart = useCallback(() => {
    if (!themeData?.id) return;

    createParticipationMutation({
      variables: {
        themeId: themeData.id,
        nextQuestionnaire: toBoolean(searchParams.get('nextQuestionnaire')),
        questionnaireOrder: Number.parseInt(searchParams.get('questionnaireOrder') || '', 10),
        respondentName,
        respondentEmail,
        token: searchParams.get('token'),
      },
      onCompleted: ({ jwt }) => navigate(`/0/0/${jwt}`, { replace: true }),
      onError: () => null,
    });
  }, [createParticipationMutation, themeData?.id, respondentName, respondentEmail, searchParams, navigate]);

  useEffect(() => {
    if (themeData?.startVariant === 'SKIP') {
      handleStart();
    }
  }, [themeData, handleStart]);

  if (themeLoading) return <Loading />;

  if (!themeData || error) return <p>Something went wrong</p>;

  if (themeData.startVariant === 'SKIP') return <Loading />;

  const translation = getTranslation(themeData);

  if (themeData.startVariant === 'LIST') {
    return (
      <Box textAlign="center">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <LanguageSelector
            languages={themeData.translations.map((tr) => tr.language)}
            defaultLanguage={themeData.defaultLanguage}
          />
        </Box>
        <Typography variant="h2" gutterBottom>
          {translation.startTitle}
        </Typography>
        <Typography marginBottom={4} color="black">
          {translation.startBody}
        </Typography>
        {themeData.showVisualisation && (
          <TextField
            margin="normal"
            fullWidth
            label={capitalize(t('general.name'))}
            onChange={({ target }) => setRespondentName(target.value)}
          />
        )}

        <LoadingButton
          onClick={handleStart}
          loading={loadingCreateParticipation}
        >
          {translation.startButton}
        </LoadingButton>
      </Box>
    );
  }

  if (themeData.startVariant === 'COLUMNS') {
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <LanguageSelector
            languages={themeData.translations.map((tr) => tr.language)}
            defaultLanguage={themeData.defaultLanguage}
          />
        </Box>
        <Grid
          container
          marginBottom={4}
          justifyContent="space-around"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              width={100}
              image={themeData.startImage}
              style={{ objectFit: 'contain' }}
              sx={{ px: { xs: 0, md: 4 }, height: { xs: 150, md: 500 } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ px: { xs: 2, md: 4 } }}>
              <Typography variant="h2" marginBottom={4}>
                {translation.startTitle}
              </Typography>
              <Typography marginBottom={4}>
                {translation.startBody}
              </Typography>
              {themeData.showVisualisation && (
                <TextField
                  margin="normal"
                  fullWidth
                  label={capitalize(t('general.name'))}
                  onChange={({ target }) => setRespondentName(target.value)}
                />
              )}
              {themeData.hostMatch === 'wijnvoordeel.events.tasteindex.com' && (
                <TextField
                  margin="normal"
                  fullWidth
                  label={capitalize(t('general.email'))}
                  onChange={({ target }) => setRespondentEmail(target.value)}
                />
              )}

              <LoadingButton
                onClick={handleStart}
                loading={loadingCreateParticipation}
              >
                {translation.startButton}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return null;
};

export default Start;
